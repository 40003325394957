*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

button {
  color: inherit;
  cursor: pointer;
  background-color: #0000;
  border-width: 0;
  padding: 0;
}

figure {
  margin: 0;
}

ul, ol, dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

p {
  margin: 0;
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
  margin: 0;
  padding: 0;
}

#gl_canvas {
  width: 100vw;
  height: 100vh;
  display: block;
}

#effect_list {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
}

#menu, #menu a {
  color: #fff;
  font-family: monospace;
  font-size: 12pt;
}

#menu {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
}

/*# sourceMappingURL=index.8643847f.css.map */
