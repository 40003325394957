/* Css Reset */
*, *::before, *::after { box-sizing:border-box; padding: 0; margin: 0;}
a { text-decoration:none; color:inherit; cursor:pointer; }
button { background-color:transparent; color:inherit; border-width:0; padding:0; cursor:pointer; }
figure{ margin:0; }
ul, ol, dd { margin:0; padding:0; list-style:none; }
h1, h2, h3, h4, h5, h6 { margin:0; font-size:inherit; font-weight:inherit; }
p { margin:0; }
cite { font-style:normal; }
fieldset { border-width:0; padding:0; margin:0; }

/* canvsas */
#gl_canvas {
  display: block;
  width: 100vw;
  height: 100vh;
}

/* effect list */
#effect_list {
  position:fixed;
  bottom: 1rem;
  left: 1rem;
}

#menu, #menu a {
  font-family: monospace;
  font-size: 12pt;
  color: white;
}

#menu {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}